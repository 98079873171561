import * as E from 'fp-ts/lib/Either';
import type { AuthToken } from '@dock/types-dock-partner';
import { ACCESS_TOKEN, AUTH_ACCESS_TOKEN, REFRESH_TOKEN, DEVICE_TOKEN } from './constants';
import { safeParseJson } from './utils';
import { DockError } from './errorHandler';
import { camelizeKeys } from './formatters';

export type LoginToken = {
    authToken: string;
    actorId?: string;
    cryptokeyId: string;
    cryptokey: string;
    name: string;
    email: string;
};

const isValidLoginTokenObject = (object: LoginToken): boolean =>
    Object.hasOwn(object, 'authToken') &&
    Object.hasOwn(object, 'cryptokeyId') &&
    Object.hasOwn(object, 'cryptokey') &&
    Object.hasOwn(object, 'name') &&
    Object.hasOwn(object, 'email');

const safeBase64Decoder = (base64: string): string | DockError => {
    // Check if the input is a valid base64 string
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if (!base64regex.test(base64)) {
        return new DockError('invalid base64 string');
    }

    return decodeURIComponent(escape(atob(base64)));
};

export const parseLoginToken = (token: string): LoginToken | DockError => {
    const decodedToken = safeBase64Decoder(token);

    if (decodedToken instanceof DockError) {
        return decodedToken;
    }

    const parsedValue = safeParseJson<LoginToken>(decodedToken);

    if (E.isRight(parsedValue)) {
        const camelized = camelizeKeys(parsedValue.right);
        const camelizedObject = Array.isArray(camelized) ? camelized[0] : camelized;

        if (!camelizedObject || !isValidLoginTokenObject(camelizedObject)) {
            return new DockError('invalid json object structure');
        }

        return camelizedObject;
    }

    return new DockError('invalid token');
};

export const isAuthenticationUrl = (url: string): boolean =>
    !!String(url).includes('/auths/') || !!String(url).includes('/tokens');

export const getAccessTokenByURL = (url: string): string | null => {
    const isAuthsUrl = isAuthenticationUrl(url);
    const tokenType = isAuthsUrl ? AUTH_ACCESS_TOKEN : ACCESS_TOKEN;
    const accessTokenFromStorage = localStorage.getItem(tokenType);

    if (!accessTokenFromStorage) {
        return null;
    }

    if (isAuthsUrl) {
        const parsedAuthToken = safeParseJson<string>(accessTokenFromStorage || '');

        return E.isRight(parsedAuthToken) ? parsedAuthToken.right : null;
    }

    const parsedToken = safeParseJson<AuthToken>(accessTokenFromStorage || '');

    return E.isRight(parsedToken) ? parsedToken.right.token : null;
};

export const getTokenFromLocalStorage =
    <ReturnType>(tokenKey: string) =>
    (): ReturnType | null => {
        const token = localStorage.getItem(tokenKey);
        const parsedValue = safeParseJson<ReturnType>(token || '');

        if (E.isLeft(parsedValue)) {
            return null;
        }

        return parsedValue.right || null;
    };

export const getRefreshTokenFromStorage = getTokenFromLocalStorage<AuthToken>(REFRESH_TOKEN);

export const getAccessTokenFromStorage = getTokenFromLocalStorage<AuthToken>(ACCESS_TOKEN);

export const getAuthAccessTokenFromStorage = getTokenFromLocalStorage<AuthToken>(AUTH_ACCESS_TOKEN);

export const getDeviceTokenFromStorage = getTokenFromLocalStorage<string>(DEVICE_TOKEN);
