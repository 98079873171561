export enum DockColor {
    WHITE = '#FFFFFF',
    BLACK = '#000000',
    PRIMARY_50 = '#EBF3F9',
    PRIMARY_100 = '#E0EAF3',
    PRIMARY_300 = '#4D84B9',
    PRIMARY_500 = '#004F9B',
    PRIMARY_700 = '#003F89',
    PRIMARY_900 = '#00266D',
    PRIMARY_1100 = '#000D3E',
    GREY_800 = '#111111',
    GREY_700 = '#172634',
    GREY_600 = '#525252',
    GREY_400 = '#808080',
    GREY_300 = '#F3F8FB',
    GREY_200 = '#D8D8D8',
    GREY_100 = '#FAFAFA',
    GREY_500 = '#F0F0F0',
    GREY_50 = '#F9FAFC',
    NEUTRAL_200 = '#E7E8EA',
    NEUTRAL_500 = '#727885',
    NEUTRAL_600 = '#535B6B',
    NEUTRAL_700 = '#353E51',
    NEUTRAL_900 = '#111C32',
    SYSTEM_POSITIVE = '#00660E',
}
