export const getDateDaysAgo = (date: Date) => (days: number) =>
    new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - days,
        date.getHours(),
        date.getMinutes()
    );

export const getISOStringFromDaysAgo = (n: number) => getDateDaysAgo(new Date())(n).toISOString();

export const getDateFromDaysAgo = getDateDaysAgo(new Date());

type PrefilDateArray = [Date | undefined, Date | undefined];

export const prefillDatesFromAlways = (from?: string, to?: string): PrefilDateArray => [
    from ? new Date(from) : undefined,
    to ? new Date(to) : undefined,
];
