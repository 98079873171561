import { InternalRequestConfig } from './http';
import { DockError } from './errorHandler';
import { StatusCodes } from './utils';

export class GenericServiceError<T> extends DockError {
    status: string | undefined;

    request: string | undefined;

    reason: T | undefined;

    errorCode: string | undefined;

    config: InternalRequestConfig | undefined;

    constructor(
        message: string,
        code?: number,
        url?: string,
        reason?: T,
        errorCode?: string,
        config?: InternalRequestConfig
    ) {
        super(message);
        this.status = code ? StatusCodes[code] : this.name;
        this.code = code ?? 0;
        this.request = url;
        this.name = 'ServiceError';
        this.reason = reason;
        this.errorCode = errorCode;
        this.config = config;
    }
}
